<template>
    <div>
        <loading v-if="isLoading"/>
        <div v-else>
            <div class="level" >
                <div class="level-left">
                    <multiselect
                        class="level-item"
                        style="width: 80px"
                        :allow-empty="false"
                        :options="[2023, 2024, 2025]"
                        v-model="year"
                    />
                    <button
                        v-if="!published"
                        class="button is-success level-item"
                        @click="publish(true)"
                    >Auf Website veröffentlichen</button>

                    <button
                        v-if="published"
                        class="button is-success level-item"
                        @click="publish(true)"
                    >Daten aktualisieren</button>
                    <button
                        v-if="published"
                        class="button is-danger  level-item"
                        @click="publish(false)"
                    >Von Website löschen</button>

                </div>
            </div>
            <div class="level">
                <div class="level-left">
                    <span class="title subtitle">Veröffentlicht für {{year}}: {{published ? published : 'Nicht veröffentlicht'}}</span>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import Mixin from '../tab';
import {ToggleSwitch, Multiselect} from '@components/form';
import moment from 'moment';
import {publishOrderConcept, getAxios} from '@api'
import {notifySuccess} from "@components/Notification";
import Loading from "@components/Loading";

export default {
    mixins: [ Mixin ],

    computed: {
        published: function () {
            if(!this.item.websites){
                return '';
            }
            let website = this.item.websites.find(website => website.catalogYear === this.year);
            return website && website.publishedOnWebsite ? moment(website.publishedOnWebsite, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null
        },
    },

    data: function() {
        return {
            year: 2025,
            isLoading: false,
        }
    },

    methods: {
        publish: function (shouldBePublished = true) {
            publishOrderConcept({
                id: this.item.id,
                year: this.year,
                data: {},
                params: {
                    publish: shouldBePublished,
                    _groups: ['concept_website_export', 'concept_day_write', 'concept_order:websites', 'concept_order_read']
                }
            }). then(response => {
                notifySuccess('Die Daten für die Website wurden aktualisiert');
                this.item.websites = response.data.websites;
            })
        }
    },

    mounted: function () {
        this.isLoading = true;
        getAxios('order_concept_websites', {
            'orderConcept.id': this.item.id
        }).then(response => {
            this.isLoading = false;
            this.$store.commit('updateData', {
                websites: response.data,
            });
        })
    },

    components: {
        Loading,
        ToggleSwitch,
        Multiselect
    },
}
</script>
