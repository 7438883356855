var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.formType === "modal"
        ? _c(
            "modal-form",
            {
              attrs: {
                editTitle: _vm.editTitle,
                createTitle: _vm.createTitle,
                isLoading: _vm.isLoading,
              },
            },
            [
              _vm.visible
                ? _c("input-fields", { attrs: { formKey: _vm.formKey } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }