var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "redesign-table" },
    [
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _vm.label
            ? _c("div", { staticClass: "table-label" }, [
                _vm._v(_vm._s(_vm.label)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasHeader
            ? _c("div", { staticClass: "row is-header" }, [
                _c("div", { staticClass: "level is-full-width" }, [
                  _c(
                    "div",
                    {
                      staticClass: "level-left",
                      class: _vm.tableExport ? "" : "is-full-width",
                    },
                    [_vm._t("header")],
                    2
                  ),
                  _vm._v(" "),
                  _vm.tableExport
                    ? _c("div", { staticClass: "level-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            class: { "is-loading": _vm.tableExport.loading },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.$emit("export")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.tableExport.label))]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasSlotFilters && !_vm.hideFilterRow
            ? _c(
                "filters",
                {
                  staticClass: "row is-filters",
                  attrs: { options: _vm.options },
                  on: {
                    input: function ($event) {
                      return _vm.refresh()
                    },
                  },
                  model: {
                    value: _vm.filters,
                    callback: function ($$v) {
                      _vm.filters = $$v
                    },
                    expression: "filters",
                  },
                },
                [_vm._t("extraFilters")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("filters", null, { options: _vm.options }),
          _vm._v(" "),
          _vm._t(
            "head",
            function () {
              return [
                _c(
                  "div",
                  { staticClass: "row is-head" },
                  [
                    _vm.checkable
                      ? _c(
                          "div",
                          { staticClass: "cell is-icon-cell is-relative" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkAll,
                                  expression: "checkAll",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.checkAll)
                                  ? _vm._i(_vm.checkAll, null) > -1
                                  : _vm.checkAll,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.checkAll,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.checkAll = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.checkAll = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.checkAll = $$c
                                  }
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.visibleCols, function (col) {
                      return _c(
                        "div",
                        {
                          key: col.key,
                          staticClass: "cell",
                          class: {
                            "is-sortable": col.sortable,
                            "is-order-asc":
                              _vm.orderBy === col.key &&
                              _vm.orderDirection === "ASC",
                            "is-order-desc":
                              _vm.orderBy === col.key &&
                              _vm.orderDirection === "DESC",
                            [col.class]: !!col.class,
                          },
                          style: col.style,
                          attrs: { title: col.title },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.sortBy(col)
                            },
                          },
                        },
                        [_vm._v(_vm._s(col.label))]
                      )
                    }),
                    _vm._v(" "),
                    _vm.hasActions
                      ? _c("div", { staticClass: "cell is-actions" }, [
                          _vm.isAddable
                            ? _c(
                                "i",
                                {
                                  staticClass: "material-icons is-pointer",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleAdd.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("add_circle")]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
            null,
            { visibleCols: _vm.visibleCols }
          ),
          _vm._v(" "),
          _vm.isLoading
            ? _c("loading", _vm._b({}, "loading", _vm.loadingText, false))
            : [
                _vm.rows.length > 0
                  ? _c(
                      "div",
                      { class: _vm.isZebra ? "is-zebra" : "" },
                      [
                        _vm._t(
                          "default",
                          function () {
                            return [
                              _vm._l(_vm.rows, function (row, index) {
                                return [
                                  row.editing
                                    ? _vm._t("row-form", null, null, {
                                        options: _vm.options,
                                        row,
                                      })
                                    : _c(
                                        _vm.rowTag,
                                        {
                                          key: row.id,
                                          tag: "component",
                                          class: [
                                            "row",
                                            row._crudTableHighlighted
                                              ? "is-light-red"
                                              : "",
                                          ],
                                          attrs: {
                                            href: _vm.rowURL(row),
                                            target: _vm.target,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "rowClicked",
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.checkable
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-icon-cell is-relative",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.checkedRows,
                                                        expression:
                                                          "checkedRows",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      value: row,
                                                      checked: Array.isArray(
                                                        _vm.checkedRows
                                                      )
                                                        ? _vm._i(
                                                            _vm.checkedRows,
                                                            row
                                                          ) > -1
                                                        : _vm.checkedRows,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.checkedRows,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = row,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.checkedRows =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.checkedRows =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.checkedRows = $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.visibleCols,
                                            function (col) {
                                              return [
                                                !col.hideContent
                                                  ? [
                                                      _vm.slotExists(col.key)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key: `${row.id}-${col.key}`,
                                                              staticClass:
                                                                "cell",
                                                              class: col.class,
                                                              style: col.style,
                                                              attrs: {
                                                                "data-v-step": `table:cell:${col.key}:${index}`,
                                                              },
                                                            },
                                                            [
                                                              _vm._t(
                                                                col.key,
                                                                null,
                                                                null,
                                                                {
                                                                  row,
                                                                  options:
                                                                    _vm.options,
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm.componentExists(
                                                            col.component
                                                          )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key: `${row.id}-${col.key}`,
                                                              staticClass:
                                                                "cell",
                                                              class: col.class,
                                                              style: col.style,
                                                              attrs: {
                                                                "data-v-step": `table:cell:${col.key}:${index}`,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                col.component,
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    row: row,
                                                                    value:
                                                                      _vm.get(
                                                                        row,
                                                                        col.key
                                                                      ),
                                                                    options:
                                                                      _vm.options,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : col.noHtml
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key: `${row.id}-${col.key}`,
                                                              staticClass:
                                                                "cell",
                                                              class: col.class,
                                                              style: col.style,
                                                              attrs: {
                                                                "data-v-step": `table:cell:${col.key}:${index}`,
                                                                title: _vm._f(
                                                                  "stripHtml"
                                                                )(
                                                                  _vm.colValue(
                                                                    row,
                                                                    col.key
                                                                  )
                                                                ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "stripHtml"
                                                                  )(
                                                                    _vm.colValue(
                                                                      row,
                                                                      col.key
                                                                    )
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _c("div", {
                                                            key: `${row.id}-${col.key}`,
                                                            staticClass: "cell",
                                                            class: col.class,
                                                            style: col.style,
                                                            attrs: {
                                                              "data-v-step": `table:cell:${col.key}:${index}`,
                                                              title:
                                                                _vm.colValue(
                                                                  row,
                                                                  col.key
                                                                ),
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.colValue(
                                                                  row,
                                                                  col.key
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm._t("extraCols", null, {
                                            row: row,
                                          }),
                                          _vm._v(" "),
                                          _vm.hasActions
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-actions",
                                                  attrs: {
                                                    "data-v-step": `table:cell:actions:${index}`,
                                                  },
                                                },
                                                [
                                                  _vm._t("actions", null, {
                                                    row: row,
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.showUpdate
                                                    ? _c(
                                                        "update-popover",
                                                        {
                                                          attrs: {
                                                            update: {
                                                              updatedAt:
                                                                row.updatedAt,
                                                              updatedBy:
                                                                row.updatedBy,
                                                            },
                                                            api:
                                                              "api/" +
                                                              _vm.api +
                                                              "/" +
                                                              row.id,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-edit",
                                                              attrs: {
                                                                title:
                                                                  "Letzte Aktualisierung",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "material-icons",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "update"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasChildAction
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-edit",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleChildAction(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons is-pointer",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "add_circle  "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.isEditable
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-edit",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleEdit(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [_vm._v("edit  ")]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.deleteRow
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-delete",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleDelete(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [_vm._v("delete")]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                  _vm._v(" "),
                                  _vm._t("subrow", null, null, {
                                    options: _vm.options,
                                    row,
                                  }),
                                ]
                              }),
                            ]
                          },
                          null,
                          { rows: _vm.rows, options: _vm.options }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "cell has-text-centered" },
                        [
                          !_vm.load
                            ? [_vm._v("Noch keine Daten geladen!")]
                            : [_vm._v("Keine Einträge")],
                        ],
                        2
                      ),
                    ]),
                _vm._v(" "),
                _vm.showSum
                  ? _c(
                      "div",
                      { staticClass: "row is-head" },
                      [
                        _vm._l(_vm.visibleCols, function (col) {
                          return _c(
                            "div",
                            {
                              key: col.key,
                              staticClass: "cell",
                              class: {
                                [col.class]: !!col.class,
                              },
                              style: col.style,
                            },
                            [
                              col.showSum === "price"
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.calculateSum(col))),
                                  ])
                                : col.showSum
                                ? _c("span", [_vm._v(_vm._s(col.showSum))])
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.isEditable || _vm.deleteRow || _vm.hasActions
                          ? _c("div", { staticClass: "cell is-actions" }, [
                              _vm.isAddable
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons is-pointer",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleAdd.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("add_circle")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isLoading && _vm.footer
        ? _c(
            "div",
            {
              staticClass: "redesign-table__footer",
              class: { "py-3 is-justify-content-center": _vm.simplePagination },
            },
            [
              _c(
                "div",
                [
                  _vm._t(
                    "footer",
                    function () {
                      return [
                        _vm.isAddable
                          ? _c(
                              "a",
                              {
                                staticClass: "button is-primary",
                                on: { click: _vm.handleAdd },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("capitalize")(_vm.name.singular)
                                  ) + " hinzufügen"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    { checked: _vm.value, options: _vm.options }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                attrs: { simple: _vm.simplePagination },
                on: { input: _vm.refresh },
                model: {
                  value: _vm.pagination,
                  callback: function ($$v) {
                    _vm.pagination = $$v
                  },
                  expression: "pagination",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("form", null, { options: _vm.options }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }