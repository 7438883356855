import moment from 'moment';
import Top    from './TopPart';
import Bottom from './BottomPart';


moment.locale('de');


const baseURL = window.location.origin;

const emailParse = function (email) {
    if (email) {
        let htmlText = `<p>&nbsp;</p><p>&nbsp;</p><div style="border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0 0 0">`;
        htmlText    +=      `<p class=MsoNormal><span style="mso-fareast-font-family:Times New Roman;mso-fareast-language:ZH-CN">`
        htmlText    +=          '<b>Von:</b> ' + (email.fromName ? email.fromName : email.fromAddress) + ' &lt;' + email.fromAddress + '&gt;' + '<br>'
        htmlText    +=          '<b>Gesendet:</b> ' + moment(email.date, 'DD.MM.YYYY HH:mm:ss').format('LLLL') + ' <br>'
        htmlText    +=          '<b>An:</b> ' + email.toAddresses.map(contact =>
                                (contact.name ? contact.name : contact.email) + ' &lt;' + contact.email + '&gt;').join(', ')
                                + '<br>';
        htmlText    += '        <b>Betreff:</b> ' + email.subject;
        htmlText    +=      '</span></p>'
        htmlText    +=  '</div>'


        if (email.textHtml) {

            let content = new DOMParser().parseFromString(email.textHtml, 'text/html');

            //     Removing base because it interferes with our js code   <base href="some_url">
            // TODO - this will break the email links - find a better solution
            htmlText += '<div dir="ltr"><br></div>' + content.body.innerHTML;

            //clean up old wrong code
            /*htmlText = htmlText.replace(
                new RegExp('\.\./\.\./', 'g'),
                '/'
            );*/

            if(!email.attachments) {
                email.attachments = [];
            }
            for (const attachment of email.attachments) {
                if ((attachment.disposition === 'inline' || !attachment.disposition) && attachment.type === 'image') {
                    htmlText = htmlText.replace(
                        new RegExp('cid:' + attachment.id, 'g'),
                        '/files/' + attachment.filePath
                    );
                }
            }


        } else {
            htmlText += '<div dir="ltr"><br></div>' + email.textPlain;
        }

        return htmlText;

    } else {
        return '';
    }
};


export default function (body = '', phone = '', email = null) {
    return Top + `
    <div class=WordSection1>




<p class=MsoNormal><span style='mso-fareast-language:DE'>${body}</span></p>

<p class=MsoNormal align=left style='text-align:left'><span style='font-size:
11.0pt;color:#1F497D;mso-fareast-language:DE'><img border=0 width=264 height=109
src="/clientSpecific/email/image001.jpg"
style='1.133in;width:2.75in' v:shapes="Grafik_x0020_2"></span></p>


</div>
   ` +

        emailParse(email) +

        Bottom;
}
