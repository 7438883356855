<template>
    <ul class="nav" id="side-menu">
        <li v-if="!isGrantedBookKeeper">
            <button>Tagesgeschäft</button>
            <ul>
                <li><a href="/user-dashboard">Dashboard</a></li>
                <li><a href="/sales-dashboard">Sales Dashboard</a></li>
                <li><a href="/emails">Mailbox</a></li>
                <li><a href="/emails/archive">Emails Archiv</a></li>
                <li><a href="/documents/booking-plan">Belegungsplan</a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper">
            <button>Vorgänge</button>
            <ul>
                <li><a href="/orders?sort=offer_sent">Angebote</a></li>
                <li><a href="/orders?sort=offer_accepted">Buchungen</a></li>
            </ul>
        </li>

        <li>
            <button>Buchführung</button>
            <ul>
                <li><a href="/client-invoices">Kundenrechnungen </a></li>
                <li><a href="/invoices">Leistungsrechnungen </a></li>
                <li><a href="/control">Abschlusskontrolle </a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper">
            <button>Stammdaten</button>
            <ul>
                <li><a href="/clients">Kunden</a></li>
                <li><a href="/hotels">Hotels</a></li>
                <li><a href="/ferries">Fähren</a></li>
                <li><a href="/airlines">Airlines</a></li>
                <li><a href="/trains">Züge</a></li>
                <li><a href="/others?type=restaurant">Restaurants</a></li>
                <li><a href="/others">Leistungsträger</a></li>
                <li><a href="/other_service_types">Zusatzleistungen</a></li>
                <li><a href="/agencies">Agenturen</a></li>
                <li><a href="/others?type=financial">Sonstiges</a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper">
            <button>Destinationsverwaltung</button>
            <ul>
                <li><a href="/destinations">Destinationen</a></li>
                <li><a href="/areas">Regionen</a></li>
                <li><a href="/places">Orte</a></li>
                <li><a href="/ports">Häfen</a></li>
                <li><a href="/airports">Flughäfen</a></li>
                <li><a href="/train_stations">Bahnhöfe</a></li>
                <li><a href="/location-charges">Gebühren</a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper">
            <button>Reisekonzepte</button>
            <ul>
                <li><a href="/concepts/orders">Gesamtkonzepte</a></li>
                <li><a href="/concepts/days">Tageskonzepte</a></li>
                <li><a href="/routes">Routenstichpunkte</a></li>
                <li><a href="/free_texts">Freitexte</a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper">
            <button>Verwaltung</button>
            <ul>
                <li><a href="/emails/templates">Emailvorlagen</a></li>
                <li><a href="/templates/offer-intro">Vorlagen für Anschreiben</a></li>
                <li><a href="/templates/trip-details">Vorlagen für Reiseunterlagen</a></li>
                <li><a href="/templates/offer-bullets">Vorlagen für Angebotsinfos</a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper">
            <button>Kalkulationspreise</button>
            <ul>
                <li><a href="/calculation-prices/hotels">Hotels</a></li>
                <li><a href="/calculation-prices/ferries">Fähren</a></li>
                <li><a href="/calculation-prices/trains">Züge</a></li>
                <li><a href="/calculation-prices/others">Zusatzleistungen</a></li>
            </ul>
        </li>

        <li v-if="!isGrantedBookKeeper"><a href="/data-exports">Daten-Export</a></li>

        <li v-if="!isGrantedBookKeeper"><a href="/faqs">FAQs</a></li>

        <li v-if="isGrantedAdmin">
            <button>Admin</button>
            <ul>
                <li><a href="/finances">Finanzen</a></li>
                <li><a href="/admin/currencies">Wechselkurse</a></li>
                <li><a href="/statistics">Statistiken</a></li>
                <li><a href="/organisations">Organisation</a></li>
                <li><a href="/banks">Banken</a></li>
                <li><a href="/admin/settings">Einstellungen</a></li>
            </ul>
        </li>

        <li><button @click.prevent="handleLogout">Logout</button></li>
    </ul>


</template>

<script>
import {currentUser} from "@utilities/functions";

export default {
    data: function() {
        return {
            loggedInUser: currentUser(),
        }
    },

    methods: {
        handleLogout() {
            localStorage.removeItem('user');
            window.location.replace('/login');
        }
    },

    computed: {
        isGrantedAdmin: function() {
            return this.loggedInUser && this.loggedInUser.roles && this.loggedInUser.roles.includes('ROLE_ADMIN');
        },
        isGrantedBookKeeper: function() {
            return this.loggedInUser && this.loggedInUser.roles && this.loggedInUser.roles.includes('ROLE_BOOK_KEEPER');
        },
    },

    mounted() {
        this.$el.querySelectorAll('button + ul').forEach(el => {
            el.setAttribute('data-height', el.scrollHeight);
        });

        this.$el.addEventListener('click', (event) => {
            let target          = event.target,
                otherOpenButton = this.$el.querySelector('.is-open button');

            if (otherOpenButton && target !== otherOpenButton) {
                otherOpenButton.parentNode.classList.toggle('is-open');
                otherOpenButton.parentNode.querySelector('ul').style.height = 0;
            }

            if (target.nodeName.toLowerCase() === 'button') {
                let ul = target.parentNode.querySelector('ul');

                if (ul) {
                    if (target.parentNode.classList.contains('is-open')) {
                        target.parentNode.classList.remove('is-open');
                        ul.style.height = 0;

                    } else {
                        target.parentNode.classList.add('is-open');
                        ul.style.height = ul.getAttribute('data-height') + 'px';
                    }
                }

                event.preventDefault();
                return false;
            }

            return true;
        });
    }
}
</script>
