<script>

import formComponent from '../Form.vue'
export default {
    mixins: [formComponent],

    data: function () {
        return {
            formKey: 'dayConcept',
            api: 'day_concepts',
            params: {},
            emptyForm: {
                name: '',
                destinations: [],
                category: '',
                isHidden: false,
                subtitle: '',
                traitTags: [],
                distance: null,
            },
            createTitle: 'Tageskonzept hinzufügen',
            editTitle: 'Tageskonzept bearbeiten',
            options: {
                categories: [
                    {key: '1', value: 'test'}
                ],
            },
        }
    },

    computed: {
        rows () {
            return [
                [
                    {class: ['is-one-third'], component: 'label', value: 'Tagesüberschrift', props: {for: 'name'}, required: true},
                    {class: [], component: 'input', value: 'name', props: {}},
                ],
                [
                    {class: ['is-one-third'], component: 'label', value: 'Kategorie', props: {for: 'category'}, required: true},
                    {class: [], component: 'Multiselect', value: 'category', props: { options: this.options.categories || [], class: ['is-shorter'], 'allow-empty': false, label: 'value', 'track-by': 'key' }, required: true },
                ],
                [
                    {class: ['is-one-third'], component: 'label', value: 'Als Inaktiv markieren', props: {for: 'isHidden'}, required: true},
                    {class: [], component: 'Toggle', value: 'isHidden' },
                ],

                [
                    {class: [], component: 'hr'},
                ],

                [
                    {class: ['is-one-third'], component: 'label', value: 'Destination', props: {for: 'destinations'}, required: false},
                    {class: [], component: 'AsyncSelect', value: 'destinations', props: {multiple: true, api: 'destinations', class: ['is-shorter']}},
                ],

                [
                    {class: ['is-one-third'], component: 'label', value: 'Interne Zusatzbezeichnung', props: {for: 'subtitle'}},
                    {class: [], component: 'input', value: 'subtitle', props: {}},
                ],
                [
                    {class: ['is-one-third'], component: 'label', value: 'Tags ', props: {for: 'traitTags'}},
                    {class: [], component: 'Multiselect', value: 'traitTags', props: {multiple: true, options: [], taggable: true, updateOnTag: true, class: ['is-shorter']}},
                ],
                [
                    {class: ['is-one-third'], component: 'label', value: 'KM ', props: {for: 'distance'}},
                    {class: [], component: 'input', value: 'distance', props: {}},
                ],
            ]
        }
    },

    methods: {
    },

    components: {

    }
}

</script>
