import welcome from './welcome/EmailBody';
import eurobin from './eurobin/EmailBody';

export default function (organisationId = 1, body = '', phone = '', email = null) {
    if(organisationId === 2){
        return eurobin(body, phone, email);
    } else {
        return welcome(body, phone, email);
    }
}
