var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("i", { staticClass: "fa fa-filter fa-lg" }),
      _vm._v(" "),
      _vm.isVisible("_search")
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchValue,
                expression: "searchValue",
              },
            ],
            staticClass: "input is-shorter",
            attrs: { type: "text", placeholder: "Suchen" },
            domProps: { value: _vm.searchValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.searchValue = $event.target.value
                },
                _vm.updateSearch,
              ],
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("addresses")
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.cityValue,
                expression: "cityValue",
              },
            ],
            staticClass: "input is-shorter is-hidden-on-small",
            attrs: { type: "text", placeholder: "Ort filtern" },
            domProps: { value: _vm.cityValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.cityValue = $event.target.value
                },
                _vm.updateCity,
              ],
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("addresses.zip")
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.addressesZipValue,
                expression: "addressesZipValue",
              },
            ],
            staticClass: "input is-shorter is-hidden-on-small",
            attrs: { type: "text", placeholder: "PLZ filtern" },
            domProps: { value: _vm.addressesZipValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.addressesZipValue = $event.target.value
                },
                _vm.updateAddressesZip,
              ],
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("organisationFilter.id") && _vm.resources.organisations
        ? _c(
            "multiselect",
            {
              staticClass: "is-smaller level-item is-shorter",
              attrs: {
                options: _vm.resources.organisations,
                "track-by": "id",
                label: "name",
                placeholder: "Organisation",
              },
              model: {
                value: _vm.filterOrganisation,
                callback: function ($$v) {
                  _vm.filterOrganisation = $$v
                },
                expression: "filterOrganisation",
              },
            },
            [
              !!_vm.filterOrganisation
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterOrganisation = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("user.id") ||
      _vm.isVisible("mainUser.id") ||
      _vm.isVisible("mainUsers.id")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "id",
                "custom-label": _vm.userLabel,
                options: _vm.resources.users,
                placeholder: "Bearbeiter filtern",
              },
              model: {
                value: _vm.filterUser,
                callback: function ($$v) {
                  _vm.filterUser = $$v
                },
                expression: "filterUser",
              },
            },
            [
              !!_vm.filterUser
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterUser = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("category") && _vm.categoryOptions.length > 0
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "key",
                label: "value",
                options: _vm.categoryOptions,
                placeholder: "Kategorie filtern",
              },
              model: {
                value: _vm.filterCategory,
                callback: function ($$v) {
                  _vm.filterCategory = $$v
                },
                expression: "filterCategory",
              },
            },
            [
              !!_vm.filterCategory
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCategory = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("ports")
        ? _c(
            "div",
            { staticClass: "combined" },
            [
              _c("async-select", {
                staticClass: "is-smaller is-shorter",
                attrs: {
                  api: "ports",
                  "custom-label": (item) => item.place.name,
                  crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                  placeholder: "Port filtern",
                },
                on: {
                  load: (portOptions) =>
                    _vm.updateResources({ startPorts: portOptions }),
                },
                model: {
                  value: _vm.filterStartPort,
                  callback: function ($$v) {
                    _vm.filterStartPort = $$v
                  },
                  expression: "filterStartPort",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("async-select", {
                staticClass: "is-smaller is-shorter",
                attrs: {
                  api: "ports",
                  "custom-label": (item) => item.place.name,
                  crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                  placeholder: "Port filtern",
                },
                on: {
                  load: (portOptions) =>
                    _vm.updateResources({ endPorts: portOptions }),
                },
                model: {
                  value: _vm.filterEndPort,
                  callback: function ($$v) {
                    _vm.filterEndPort = $$v
                  },
                  expression: "filterEndPort",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("otherServices.otherServiceType.id")
        ? _c("async-select", {
            staticClass: "is-shorter",
            attrs: { api: "other_service_types", placeholder: "ZL filtern" },
            on: {
              load: (opt) => _vm.updateResources({ otherServiceTypes: opt }),
            },
            model: {
              value: _vm.filterOtherServiceType,
              callback: function ($$v) {
                _vm.filterOtherServiceType = $$v
              },
              expression: "filterOtherServiceType",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("type") && _vm.options.types
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "key",
                label: "value",
                options: _vm.options.types,
                placeholder: "Art filtern",
                multiple: _vm.isTypeMultiple,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "selection",
                    fn: function ({ values, search, isOpen }) {
                      return _vm.isTypeMultiple
                        ? [
                            values.length && !isOpen
                              ? _c(
                                  "span",
                                  { staticClass: "multiselect__single" },
                                  [
                                    _vm._v(
                                      _vm._s(values.length) +
                                        " Element(e) ausgewählt"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.filterType,
                callback: function ($$v) {
                  _vm.filterType = $$v
                },
                expression: "filterType",
              },
            },
            [
              !!_vm.filterType && !_vm.isTypeMultiple
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterType = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("otherServiceType.category") &&
      _vm.options.otherServiceTypeCategories
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "key",
                label: "value",
                options: _vm.options.otherServiceTypeCategories,
                placeholder: "Kategorie filtern",
              },
              model: {
                value: _vm.filterOtherServiceTypeCategories,
                callback: function ($$v) {
                  _vm.filterOtherServiceTypeCategories = $$v
                },
                expression: "filterOtherServiceTypeCategories",
              },
            },
            [
              !!_vm.filterOtherServiceTypeCategories
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterOtherServiceTypeCategories = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.destinationKey
        ? _c("async-select", {
            staticClass: "is-shorter",
            attrs: {
              api: "destinations",
              crudLinkTitle: "Neuen Destination hinzufügen",
              placeholder: "Destination filtern",
            },
            on: { load: (opt) => _vm.updateResources({ destinations: opt }) },
            model: {
              value: _vm.filterDestination,
              callback: function ($$v) {
                _vm.filterDestination = $$v
              },
              expression: "filterDestination",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("place.area.id") || _vm.isVisible("area.id")
        ? _c("async-select", {
            staticClass: "is-shorter",
            attrs: {
              api: "areas",
              crudLinkTitle: "Neuen Region hinzufügen",
              placeholder: "Region filtern",
            },
            on: { load: (opt) => _vm.updateResources({ areas: opt }) },
            model: {
              value: _vm.filterArea,
              callback: function ($$v) {
                _vm.filterArea = $$v
              },
              expression: "filterArea",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("place.id")
        ? _c("async-select", {
            staticClass: "is-shorter",
            attrs: {
              api: "places",
              crudLinkTitle: "Neuen Ort hinzufügen",
              placeholder: "Ort filtern",
            },
            on: { load: (opt) => _vm.updateResources({ places: opt }) },
            model: {
              value: _vm.filterPlace,
              callback: function ($$v) {
                _vm.filterPlace = $$v
              },
              expression: "filterPlace",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("agencies.id")
        ? _c("async-select", {
            staticClass: "is-shorter",
            attrs: {
              api: "agencies",
              crudLinkTitle: "Neue Agentur hinzufügen",
              placeholder: "Agenturen filtern",
              multiple: "",
            },
            on: { load: (opt) => _vm.updateResources({ agencies: opt }) },
            model: {
              value: _vm.filterAgencies,
              callback: function ($$v) {
                _vm.filterAgencies = $$v
              },
              expression: "filterAgencies",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("country")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "label",
                label: "name",
                options: _vm.resources.countries,
                placeholder: "Land filtern",
              },
              model: {
                value: _vm.filterCountry,
                callback: function ($$v) {
                  _vm.filterCountry = $$v
                },
                expression: "filterCountry",
              },
            },
            [
              !!_vm.filterCountry
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCountry = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("addresses.country")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "label",
                label: "name",
                multiple: "",
                options: _vm.resources.countries,
                placeholder: "Land filtern",
              },
              model: {
                value: _vm.filterAddressCountry,
                callback: function ($$v) {
                  _vm.filterAddressCountry = $$v
                },
                expression: "filterAddressCountry",
              },
            },
            [
              !!_vm.filterCountry
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCountry = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("importance") && _vm.options.importance
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "key",
                label: "value",
                options: _vm.options.importance,
                placeholder: "Wichtigkeit filtern",
              },
              model: {
                value: _vm.filterImportance,
                callback: function ($$v) {
                  _vm.filterImportance = $$v
                },
                expression: "filterImportance",
              },
            },
            [
              !!_vm.filterImportance
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterImportance = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("locale") && _vm.options.locale
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "key",
                label: "value",
                options: _vm.options.locale,
                placeholder: "Sprache filtern",
              },
              model: {
                value: _vm.filterLocale,
                callback: function ($$v) {
                  _vm.filterLocale = $$v
                },
                expression: "filterLocale",
              },
            },
            [
              !!_vm.filterLocale
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterLocale = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("difficulty") && _vm.options.difficulties
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                "track-by": "key",
                label: "value",
                options: _vm.options.difficulties,
                placeholder: "Schwierigkeit filtern",
              },
              model: {
                value: _vm.filterDifficulty,
                callback: function ($$v) {
                  _vm.filterDifficulty = $$v
                },
                expression: "filterDifficulty",
              },
            },
            [
              !!_vm.filterDifficulty
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterDifficulty = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("currentStatus.status") && _vm.options.orderStatus
        ? _c("multiselect", {
            staticClass: "is-shorter",
            attrs: {
              "track-by": "key",
              label: "value",
              options: _vm.options.orderStatus,
              placeholder: "Reisestatus filtern",
              "close-on-select": false,
              "clear-on-select": false,
              multiple: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ values, search, isOpen }) {
                    return [
                      values.length && !isOpen
                        ? _c("span", { staticClass: "multiselect__single" }, [
                            _vm._v(_vm._s(values.length) + " Stati ausgewählt"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              2465214594
            ),
            model: {
              value: _vm.filterStatuses,
              callback: function ($$v) {
                _vm.filterStatuses = $$v
              },
              expression: "filterStatuses",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("requestStatus.status") &&
      (_vm.options.requestStatuses || _vm.options.status)
        ? _c("multiselect", {
            staticClass: "is-shorter",
            attrs: {
              options: _vm.options.requestStatuses
                ? _vm.options.requestStatuses
                : _vm.options.status,
              multiple: "",
              label: "value",
              "track-by": "key",
              placeholder: "LT-Status filtern",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ values, search, isOpen }) {
                    return [
                      values.length && !isOpen
                        ? _c("span", { staticClass: "multiselect__single" }, [
                            _vm._v(
                              _vm._s(values.length) + " LT-Stati ausgewählt"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              1515681495
            ),
            model: {
              value: _vm.filterRequestStatus,
              callback: function ($$v) {
                _vm.filterRequestStatus = $$v
              },
              expression: "filterRequestStatus",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("status") && _vm.options.type
        ? _c("multiselect", {
            staticClass: "is-shorter",
            attrs: {
              "track-by": "key",
              label: "value",
              options: _vm.options.type,
              placeholder: "Status filtern",
              "close-on-select": false,
              "clear-on-select": false,
              multiple: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ values, search, isOpen }) {
                    return [
                      values.length && !isOpen
                        ? _c("span", { staticClass: "multiselect__single" }, [
                            _vm._v(_vm._s(values.length) + " Stati ausgewählt"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              2465214594
            ),
            model: {
              value: _vm.filterClientInvoiceStatuses,
              callback: function ($$v) {
                _vm.filterClientInvoiceStatuses = $$v
              },
              expression: "filterClientInvoiceStatuses",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("startAt[after]") && _vm.isVisible("startAt[before]")
        ? _c(
            "div",
            { staticClass: "flatpickr-combined" },
            [
              _c("input-date", {
                model: {
                  value: _vm.filterStartAtAfter,
                  callback: function ($$v) {
                    _vm.filterStartAtAfter = $$v
                  },
                  expression: "filterStartAtAfter",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input-date", {
                model: {
                  value: _vm.filterStartAtBefore,
                  callback: function ($$v) {
                    _vm.filterStartAtBefore = $$v
                  },
                  expression: "filterStartAtBefore",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("endAt[after]") && _vm.isVisible("startAt[before]")
        ? _c(
            "div",
            { staticClass: "flatpickr-combined" },
            [
              _c("input-date", {
                model: {
                  value: _vm.filterEndAtAfter,
                  callback: function ($$v) {
                    _vm.filterEndAtAfter = $$v
                  },
                  expression: "filterEndAtAfter",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input-date", {
                model: {
                  value: _vm.filterStartAtBefore,
                  callback: function ($$v) {
                    _vm.filterStartAtBefore = $$v
                  },
                  expression: "filterStartAtBefore",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("timePeriods.endAt[after]") &&
      _vm.isVisible("timePeriods.startAt[before]")
        ? _c(
            "div",
            { staticClass: "flatpickr-combined" },
            [
              _c("input-date", {
                model: {
                  value: _vm.filterTimePeriodsEndAtAfter,
                  callback: function ($$v) {
                    _vm.filterTimePeriodsEndAtAfter = $$v
                  },
                  expression: "filterTimePeriodsEndAtAfter",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input-date", {
                model: {
                  value: _vm.filterTimePeriodsStartAtBefore,
                  callback: function ($$v) {
                    _vm.filterTimePeriodsStartAtBefore = $$v
                  },
                  expression: "filterTimePeriodsStartAtBefore",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("priceGroups.timePeriods.endAt[after]") &&
      _vm.isVisible("priceGroups.timePeriods.startAt[before]")
        ? _c(
            "div",
            { staticClass: "flatpickr-combined" },
            [
              _c("input-date", {
                model: {
                  value: _vm.filterPriceGroupsTimePeriodsEndAtAfter,
                  callback: function ($$v) {
                    _vm.filterPriceGroupsTimePeriodsEndAtAfter = $$v
                  },
                  expression: "filterPriceGroupsTimePeriodsEndAtAfter",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input-date", {
                model: {
                  value: _vm.filterPriceGroupsTimePeriodsStartAtBefore,
                  callback: function ($$v) {
                    _vm.filterPriceGroupsTimePeriodsStartAtBefore = $$v
                  },
                  expression: "filterPriceGroupsTimePeriodsStartAtBefore",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("months")
        ? _c(
            "div",
            { staticClass: "flatpickr-combined" },
            [
              _c("input-month", {
                ref: "startAt",
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                        "Backspace",
                        "Delete",
                        "Del",
                      ])
                    )
                      return null
                    return _vm.$refs.startAt.clearDate.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.filterMonthStartAt,
                  callback: function ($$v) {
                    _vm.filterMonthStartAt = $$v
                  },
                  expression: "filterMonthStartAt",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input-month", {
                ref: "endAt",
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                        "Backspace",
                        "Delete",
                        "Del",
                      ])
                    )
                      return null
                    return _vm.$refs.endAt.clearDate.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.filterMonthEndAt,
                  callback: function ($$v) {
                    _vm.filterMonthEndAt = $$v
                  },
                  expression: "filterMonthEndAt",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("agency[exists]")
        ? _c(
            "div",
            { staticClass: "level" },
            [
              _c(
                "toggle-switch",
                {
                  attrs: { "btn-class": "is-120" },
                  model: {
                    value: _vm.filterAgencyExists,
                    callback: function ($$v) {
                      _vm.filterAgencyExists = $$v
                    },
                    expression: "filterAgencyExists",
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("Alle")]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: false } }, [
                    _vm._v("Nur Direktpreise"),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("dateText")
        ? _c(
            "div",
            { staticClass: "level" },
            [
              _c(
                "toggle-switch",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { "btn-class": "is-75" },
                  model: {
                    value: _vm.filterDateText,
                    callback: function ($$v) {
                      _vm.filterDateText = $$v
                    },
                    expression: "filterDateText",
                  },
                },
                [
                  _c("option", { attrs: { value: "w" } }, [_vm._v("1 Woche")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "M" } }, [_vm._v("1 Monat")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "individual" } }, [
                    _vm._v("Individuell"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.filterDateText === "individual"
                ? _c("input-date", {
                    staticClass: "is-date",
                    model: {
                      value: _vm.filterDateBefore,
                      callback: function ($$v) {
                        _vm.filterDateBefore = $$v
                      },
                      expression: "filterDateBefore",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("date[after]") && _vm.isVisible("date[before]")
        ? _c(
            "div",
            { staticClass: "flatpickr-combined" },
            [
              _c("input-date", {
                staticClass: "is-date",
                model: {
                  value: _vm.filterDateAfter,
                  callback: function ($$v) {
                    _vm.filterDateAfter = $$v
                  },
                  expression: "filterDateAfter",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("-")]),
              _vm._v(" "),
              _c("input-date", {
                staticClass: "is-date",
                model: {
                  value: _vm.filterDateBefore,
                  callback: function ($$v) {
                    _vm.filterDateBefore = $$v
                  },
                  expression: "filterDateBefore",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("agency.id")
        ? _c("async-select", {
            attrs: {
              api: "agencies",
              crudLinkTitle: "Neue Agentur hinzufügen",
              placeholder: "Agenturen filtern",
              multiple: "",
            },
            on: { load: (opt) => _vm.updateResources({ agencies: opt }) },
            model: {
              value: _vm.filterAgency,
              callback: function ($$v) {
                _vm.filterAgency = $$v
              },
              expression: "filterAgency",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("hotelType") && _vm.options.hotelTypes
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.hotelTypes,
                "track-by": "name",
                label: "name",
                placeholder: "Hotelkategorie filtern",
              },
              model: {
                value: _vm.filterHotelType,
                callback: function ($$v) {
                  _vm.filterHotelType = $$v
                },
                expression: "filterHotelType",
              },
            },
            [
              !!_vm.filterHotelType
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterHotelType = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("days")
        ? _c("days", {
            attrs: { picker: "" },
            model: {
              value: _vm.filterDays,
              callback: function ($$v) {
                _vm.filterDays = $$v
              },
              expression: "filterDays",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("board") && _vm.options.board
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.board,
                "track-by": "key",
                label: "value",
                placeholder: "Verpflegung filtern",
              },
              model: {
                value: _vm.filterBoard,
                callback: function ($$v) {
                  _vm.filterBoard = $$v
                },
                expression: "filterBoard",
              },
            },
            [
              !!_vm.filterBoard
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterBoard = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("roomType") && _vm.options.roomType
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.roomType,
                "track-by": "name",
                label: "name",
                placeholder: "Zimmertyp filtern",
              },
              model: {
                value: _vm.filterRoomType,
                callback: function ($$v) {
                  _vm.filterRoomType = $$v
                },
                expression: "filterRoomType",
              },
            },
            [
              !!_vm.filterRoomType
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterRoomType = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("cabinType") && _vm.options.cabinTypes
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.cabinTypes,
                "track-by": "name",
                label: "name",
                placeholder: "Kabinentyp filtern",
              },
              model: {
                value: _vm.filterCabinType,
                callback: function ($$v) {
                  _vm.filterCabinType = $$v
                },
                expression: "filterCabinType",
              },
            },
            [
              !!_vm.filterCabinType
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCabinType = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("years")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.resources.years,
                placeholder: "Katalogjahr filtern",
                multiple: "",
              },
              model: {
                value: _vm.filterYears,
                callback: function ($$v) {
                  _vm.filterYears = $$v
                },
                expression: "filterYears",
              },
            },
            [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("tags") && _vm.resources.tags
        ? _c("multiselect", {
            staticClass: "is-shorter",
            attrs: {
              options: _vm.resources.tags,
              "track-by": "label",
              label: "name",
              placeholder: "Symbole filtern",
            },
            model: {
              value: _vm.filterTags,
              callback: function ($$v) {
                _vm.filterTags = $$v
              },
              expression: "filterTags",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("traitTags")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.traitTags ? _vm.options.traitTags : [],
                placeholder: "Tags filtern",
                taggable: true,
                multiple: "",
              },
              on: {
                tag: (newTag) => {
                  _vm.resources.traitTags = _vm.filter["traitTags"]
                    ? _vm.filter["traitTags"]
                    : []
                  _vm.resources.traitTags.push(newTag)
                  _vm.update("traitTags", _vm.resources.traitTags)
                },
              },
              model: {
                value: _vm.filterTraitTags,
                callback: function ($$v) {
                  _vm.filterTraitTags = $$v
                },
                expression: "filterTraitTags",
              },
            },
            [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("checkedAt") && _vm.options._importChecked
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options._importChecked,
                "track-by": "key",
                label: "value",
              },
              model: {
                value: _vm.filterCheckedAt,
                callback: function ($$v) {
                  _vm.filterCheckedAt = $$v
                },
                expression: "filterCheckedAt",
              },
            },
            [
              !!_vm.filterCheckedAt
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCheckedAt = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("receivesCatalog")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: [
                  { key: true, value: "Katalog: ja" },
                  { key: false, value: "Katalog: nein" },
                ],
                "track-by": "key",
                label: "value",
                placeholder: "Katalog",
              },
              model: {
                value: _vm.filterReceivesCatalog,
                callback: function ($$v) {
                  _vm.filterReceivesCatalog = $$v
                },
                expression: "filterReceivesCatalog",
              },
            },
            [
              !!_vm.filterReceivesCatalog
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterReceivesCatalog = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("checkStatus") && _vm.options.checkedStatusList
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.checkedStatusList,
                "track-by": "key",
                label: "value",
                placeholder: "Status filtern",
              },
              model: {
                value: _vm.filterCheckStatus,
                callback: function ($$v) {
                  _vm.filterCheckStatus = $$v
                },
                expression: "filterCheckStatus",
              },
            },
            [
              !!_vm.filterCheckStatus
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCheckStatus = null
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("checkedCatalogText") && _vm.options.checkedCatalogText
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter",
              attrs: {
                options: _vm.options.checkedCatalogText,
                "track-by": "key",
                label: "value",
                placeholder: "Katalog filtern",
              },
              model: {
                value: _vm.filterCatalog,
                callback: function ($$v) {
                  _vm.filterCatalog = $$v
                },
                expression: "filterCatalog",
              },
            },
            [
              !!_vm.filterCatalog
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterCatalog = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("homepageStatus")
        ? _c(
            "multiselect",
            {
              attrs: {
                "allow-empty": true,
                label: "label",
                "track-by": "value",
                options: [
                  { label: "online", value: true },
                  { label: "offline", value: false },
                ],
                placeholder: "Homepage",
              },
              model: {
                value: _vm.filterHomepageStatus,
                callback: function ($$v) {
                  _vm.filterHomepageStatus = $$v
                },
                expression: "filterHomepageStatus",
              },
            },
            [
              !!_vm.filterHomepageStatus
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterHomepageStatus = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible("bookingSituation") &&
      _vm.isVisible("bookingSituation[not]")
        ? _c(
            "multiselect",
            {
              staticClass: "is-shorter is-medium",
              attrs: {
                options: _vm.resources.bookingSituation,
                "track-by": "key",
                label: "value",
                placeholder: "Abgeschlossen?",
              },
              model: {
                value: _vm.filterBookingSituation,
                callback: function ($$v) {
                  _vm.filterBookingSituation = $$v
                },
                expression: "filterBookingSituation",
              },
            },
            [
              _vm.filterBookingSituation
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    attrs: { slot: "clear" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.filterBookingSituation = ""
                      },
                    },
                    slot: "clear",
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }